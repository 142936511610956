/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestBillingPortalResponse
 */
export interface RequestBillingPortalResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RequestBillingPortalResponse
     */
    success: boolean;
}

/**
 * Check if a given object implements the RequestBillingPortalResponse interface.
 */
export function instanceOfRequestBillingPortalResponse(value: object): value is RequestBillingPortalResponse {
    if (!('success' in value) || value['success'] === undefined) return false;
    return true;
}

export function RequestBillingPortalResponseFromJSON(json: any): RequestBillingPortalResponse {
    return RequestBillingPortalResponseFromJSONTyped(json, false);
}

export function RequestBillingPortalResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestBillingPortalResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'],
    };
}

export function RequestBillingPortalResponseToJSON(json: any): RequestBillingPortalResponse {
    return RequestBillingPortalResponseToJSONTyped(json, false);
}

export function RequestBillingPortalResponseToJSONTyped(value?: RequestBillingPortalResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'success': value['success'],
    };
}

