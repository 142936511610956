/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiServerExceptionResponse,
  CheckoutSessionCreateRequest,
  CheckoutSessionResponse,
  CheckoutSessionStatusResponse,
  ProAccountCheckoutSessionCreateRequest,
  ProAccountCheckoutSessionResponse,
  SubscriptionStatusData,
} from '../models/index';
import {
    ApiServerExceptionResponseFromJSON,
    ApiServerExceptionResponseToJSON,
    CheckoutSessionCreateRequestFromJSON,
    CheckoutSessionCreateRequestToJSON,
    CheckoutSessionResponseFromJSON,
    CheckoutSessionResponseToJSON,
    CheckoutSessionStatusResponseFromJSON,
    CheckoutSessionStatusResponseToJSON,
    ProAccountCheckoutSessionCreateRequestFromJSON,
    ProAccountCheckoutSessionCreateRequestToJSON,
    ProAccountCheckoutSessionResponseFromJSON,
    ProAccountCheckoutSessionResponseToJSON,
    SubscriptionStatusDataFromJSON,
    SubscriptionStatusDataToJSON,
} from '../models/index';

export interface SubscriptionApiCreateCheckoutSessionRequest {
    checkoutSessionCreateRequest: CheckoutSessionCreateRequest;
}

export interface SubscriptionApiCreateProAccountCheckoutSessionRequest {
    proAccountCheckoutSessionCreateRequest: ProAccountCheckoutSessionCreateRequest;
}

export interface SubscriptionApiGetCheckoutSessionRequest {
    checkoutSessionId: string;
}

export interface SubscriptionApiGetSubscriptionStatusForDomainRequest {
    domainId: string;
}

/**
 * 
 */
export class SubscriptionApi extends runtime.BaseAPI {

    /**
     * Create a Stripe Checkout session. Default is a redirect to the Hosted Checkout Page.
     */
    async createCheckoutSessionRaw(requestParameters: SubscriptionApiCreateCheckoutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckoutSessionResponse>> {
        if (requestParameters['checkoutSessionCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'checkoutSessionCreateRequest',
                'Required parameter "checkoutSessionCreateRequest" was null or undefined when calling createCheckoutSession().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/subscription/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckoutSessionCreateRequestToJSON(requestParameters['checkoutSessionCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckoutSessionResponseFromJSON(jsonValue));
    }

    /**
     * Create a Stripe Checkout session. Default is a redirect to the Hosted Checkout Page.
     */
    async createCheckoutSession(requestParameters: SubscriptionApiCreateCheckoutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckoutSessionResponse> {
        const response = await this.createCheckoutSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a Stripe Checkout session for a PRO Account. Default is a redirect to the Hosted Checkout Page.
     */
    async createProAccountCheckoutSessionRaw(requestParameters: SubscriptionApiCreateProAccountCheckoutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProAccountCheckoutSessionResponse>> {
        if (requestParameters['proAccountCheckoutSessionCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'proAccountCheckoutSessionCreateRequest',
                'Required parameter "proAccountCheckoutSessionCreateRequest" was null or undefined when calling createProAccountCheckoutSession().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/subscription/proAccount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProAccountCheckoutSessionCreateRequestToJSON(requestParameters['proAccountCheckoutSessionCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProAccountCheckoutSessionResponseFromJSON(jsonValue));
    }

    /**
     * Create a Stripe Checkout session for a PRO Account. Default is a redirect to the Hosted Checkout Page.
     */
    async createProAccountCheckoutSession(requestParameters: SubscriptionApiCreateProAccountCheckoutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProAccountCheckoutSessionResponse> {
        const response = await this.createProAccountCheckoutSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a Stripe Checkout session
     */
    async getCheckoutSessionRaw(requestParameters: SubscriptionApiGetCheckoutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckoutSessionStatusResponse>> {
        if (requestParameters['checkoutSessionId'] == null) {
            throw new runtime.RequiredError(
                'checkoutSessionId',
                'Required parameter "checkoutSessionId" was null or undefined when calling getCheckoutSession().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/subscription/session/{checkoutSessionId}`.replace(`{${"checkoutSessionId"}}`, encodeURIComponent(String(requestParameters['checkoutSessionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckoutSessionStatusResponseFromJSON(jsonValue));
    }

    /**
     * Get a Stripe Checkout session
     */
    async getCheckoutSession(requestParameters: SubscriptionApiGetCheckoutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckoutSessionStatusResponse> {
        const response = await this.getCheckoutSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get subscription status for a domain
     */
    async getSubscriptionStatusForDomainRaw(requestParameters: SubscriptionApiGetSubscriptionStatusForDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionStatusData>> {
        if (requestParameters['domainId'] == null) {
            throw new runtime.RequiredError(
                'domainId',
                'Required parameter "domainId" was null or undefined when calling getSubscriptionStatusForDomain().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/subscription/domain/{domainId}`.replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters['domainId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionStatusDataFromJSON(jsonValue));
    }

    /**
     * Get subscription status for a domain
     */
    async getSubscriptionStatusForDomain(requestParameters: SubscriptionApiGetSubscriptionStatusForDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionStatusData> {
        const response = await this.getSubscriptionStatusForDomainRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
