/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ButtonOptions } from './ButtonOptions';
import {
    ButtonOptionsFromJSON,
    ButtonOptionsFromJSONTyped,
    ButtonOptionsToJSON,
    ButtonOptionsToJSONTyped,
} from './ButtonOptions';
import type { MultiButtonOptions } from './MultiButtonOptions';
import {
    MultiButtonOptionsFromJSON,
    MultiButtonOptionsFromJSONTyped,
    MultiButtonOptionsToJSON,
    MultiButtonOptionsToJSONTyped,
} from './MultiButtonOptions';

/**
 * 
 * @export
 * @interface ButtonUpdateRequest
 */
export interface ButtonUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ButtonUpdateRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ButtonUpdateRequest
     */
    domain: string;
    /**
     * 
     * @type {boolean}
     * @memberof ButtonUpdateRequest
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof ButtonUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ButtonUpdateRequest
     */
    type: ButtonUpdateRequestTypeEnum;
    /**
     * 
     * @type {ButtonOptions}
     * @memberof ButtonUpdateRequest
     */
    options: ButtonOptions;
    /**
     * 
     * @type {MultiButtonOptions}
     * @memberof ButtonUpdateRequest
     */
    multiButtonOptions?: MultiButtonOptions;
    /**
     * 
     * @type {Array<string>}
     * @memberof ButtonUpdateRequest
     */
    actions: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ButtonUpdateRequest
     */
    conditions: Array<string>;
}


/**
 * @export
 */
export const ButtonUpdateRequestTypeEnum = {
    Single: 'SINGLE',
    Multi: 'MULTI',
    Full: 'FULL',
    Dots: 'DOTS'
} as const;
export type ButtonUpdateRequestTypeEnum = typeof ButtonUpdateRequestTypeEnum[keyof typeof ButtonUpdateRequestTypeEnum];


/**
 * Check if a given object implements the ButtonUpdateRequest interface.
 */
export function instanceOfButtonUpdateRequest(value: object): value is ButtonUpdateRequest {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('domain' in value) || value['domain'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    if (!('actions' in value) || value['actions'] === undefined) return false;
    if (!('conditions' in value) || value['conditions'] === undefined) return false;
    return true;
}

export function ButtonUpdateRequestFromJSON(json: any): ButtonUpdateRequest {
    return ButtonUpdateRequestFromJSONTyped(json, false);
}

export function ButtonUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ButtonUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'domain': json['domain'],
        'active': json['active'],
        'name': json['name'],
        'type': json['type'],
        'options': ButtonOptionsFromJSON(json['options']),
        'multiButtonOptions': json['multiButtonOptions'] == null ? undefined : MultiButtonOptionsFromJSON(json['multiButtonOptions']),
        'actions': json['actions'],
        'conditions': json['conditions'],
    };
}

export function ButtonUpdateRequestToJSON(json: any): ButtonUpdateRequest {
    return ButtonUpdateRequestToJSONTyped(json, false);
}

export function ButtonUpdateRequestToJSONTyped(value?: ButtonUpdateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'domain': value['domain'],
        'active': value['active'],
        'name': value['name'],
        'type': value['type'],
        'options': ButtonOptionsToJSON(value['options']),
        'multiButtonOptions': MultiButtonOptionsToJSON(value['multiButtonOptions']),
        'actions': value['actions'],
        'conditions': value['conditions'],
    };
}

