import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Clear from "@mui/icons-material/Clear";
import IconTextChooser from "./IconTextChooser";
import { ButtonType } from "../buttonModels";
import { ExpansionPanel, ExtendedFormState } from "../../common";
import ColorPicker from "./ColorPicker";
import { Button } from "../../lib/cnb/api/public";

type MultiButtonPresentationProps = {
  formal: ExtendedFormState<Button>;
};

const MultiButtonPresentation = ({ formal }: MultiButtonPresentationProps) => {
  const iconBackgroundImageOpen =
    formal.getValue<string>(`multiButtonOptions.iconBackgroundImageOpen`)
      ?.length > 0;

  useEffect(() => {
    formal.setValue(
      `multiButtonOptions.iconTypeOpen`,
      (iconBackgroundImageOpen ? "CUSTOM" : "FONT") as NonNullable<
        Button["multiButtonOptions"]
      >["iconTypeOpen"],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconBackgroundImageOpen]);

  const iconBackgroundImageClose =
    formal.getValue<string>(`multiButtonOptions.iconBackgroundImageClose`)
      ?.length > 0;

  useEffect(() => {
    formal.setValue(
      `multiButtonOptions.iconTypeClose`,
      (iconBackgroundImageClose ? "CUSTOM" : "FONT") as NonNullable<
        Button["multiButtonOptions"]
      >["iconTypeClose"],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconBackgroundImageClose]);

  const newStyle = true;
  if (newStyle) {
    return (
      <>
        <CollapsedIconOptions formal={formal} />
        <DefaultStateOption formal={formal} />
      </>
    );
  }

  // This is basically disabled via https://github.com/callnowbutton/cnb-web/issues/839
  // Kept here for historical reasons
  return (
    <>
      <ExpansionPanel title="Collapsed icon">
        <CollapsedIconOptions formal={formal} />
      </ExpansionPanel>
      <ExpansionPanel title="Expanded icon">
        <ExpandedIconOptions formal={formal} />
      </ExpansionPanel>
      <ExpansionPanel title="Collapsed label">
        <CollapsedLabelOptions formal={formal} />
      </ExpansionPanel>
      <ExpansionPanel title="Expanded label">
        <ExpandedLabelOptions formal={formal} />
      </ExpansionPanel>
      <DefaultStateOption formal={formal} />
    </>
  );
};

const DefaultStateOption = ({ formal }: MultiButtonPresentationProps) => (
  <TextField
    label="Default state"
    select
    SelectProps={{
      native: true,
    }}
    {...formal.getTextFieldProps("multiButtonOptions.defaultState")}
  >
    {Object.entries(ButtonType.MULTI_DEFAULT_STATE).map(([mode, name]) => (
      <option key={mode} value={mode}>
        {name}
      </option>
    ))}
  </TextField>
);

const CollapsedIconOptions = ({ formal }: MultiButtonPresentationProps) => {
  return (
    <>
      <ColorPicker
        label="Button color"
        {...formal.getTextFieldProps(
          "multiButtonOptions.iconBackgroundColorOpen",
        )}
      />
      <ColorPicker
        label="Icon color"
        {...formal.getTextFieldProps("multiButtonOptions.iconColorOpen")}
      />

      <IconTextChooser
        iconTexts={ButtonType.MULTI_ICON_TEXT_OPEN}
        name={`multiButtonOptions.iconTextOpen`}
        type={`multiButtonOptions.iconTypeOpen`}
        formal={formal}
      />
      <TextField
        label="Custom image URL"
        type="url"
        {...formal.getTextFieldProps(
          `multiButtonOptions.iconBackgroundImageOpen`,
        )}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={() =>
                formal.setValue(
                  `multiButtonOptions.iconBackgroundImageOpen`,
                  "",
                )
              }
            >
              <Clear />
            </IconButton>
          ),
        }}
      />
    </>
  );
};

const ExpandedIconOptions = ({ formal }: MultiButtonPresentationProps) => {
  return (
    <>
      <ColorPicker
        label="Button color"
        {...formal.getTextFieldProps(
          "multiButtonOptions.iconBackgroundColorClose",
        )}
      />
      <ColorPicker
        label="Icon color"
        {...formal.getTextFieldProps("multiButtonOptions.iconColorClose")}
      />
      <IconTextChooser
        iconTexts={ButtonType.MULTI_ICON_TEXT_CLOSE}
        name={`multiButtonOptions.iconTextClose`}
        type={`multiButtonOptions.iconTypeClose`}
        formal={formal}
      />
      <TextField
        label="Custom image URL"
        type="url"
        {...formal.getTextFieldProps(
          `multiButtonOptions.iconBackgroundImageClose`,
        )}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={() =>
                formal.setValue(
                  `multiButtonOptions.iconBackgroundImageClose`,
                  "",
                )
              }
            >
              <Clear />
            </IconButton>
          ),
        }}
      />
    </>
  );
};

const CollapsedLabelOptions = ({ formal }: MultiButtonPresentationProps) => {
  return (
    <>
      <TextField
        label="Label text"
        {...formal.getTextFieldProps(`multiButtonOptions.labelTextOpen`)}
      />
      <ColorPicker
        label="Background color"
        {...formal.getTextFieldProps(
          "multiButtonOptions.labelBackgroundColorOpen",
        )}
      />
      <ColorPicker
        label="Text color"
        {...formal.getTextFieldProps("multiButtonOptions.labelTextColorOpen")}
      />
    </>
  );
};

const ExpandedLabelOptions = ({ formal }: MultiButtonPresentationProps) => {
  return (
    <>
      <TextField
        label="Label text"
        {...formal.getTextFieldProps(`multiButtonOptions.labelTextClose`)}
      />
      <ColorPicker
        label="Background color"
        {...formal.getTextFieldProps(
          "multiButtonOptions.labelBackgroundColorClose",
        )}
      />
      <ColorPicker
        label="Text color"
        {...formal.getTextFieldProps("multiButtonOptions.labelTextColorClose")}
      />
    </>
  );
};

export default MultiButtonPresentation;
