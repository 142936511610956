/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Button } from './Button';
import {
    ButtonFromJSON,
    ButtonFromJSONTyped,
    ButtonToJSON,
    ButtonToJSONTyped,
} from './Button';
import type { ImportDomain } from './ImportDomain';
import {
    ImportDomainFromJSON,
    ImportDomainFromJSONTyped,
    ImportDomainToJSON,
    ImportDomainToJSONTyped,
} from './ImportDomain';

/**
 * 
 * @export
 * @interface ImportButtonRequest
 */
export interface ImportButtonRequest {
    /**
     * Id of the Domain to import the buttons to
     * @type {string}
     * @memberof ImportButtonRequest
     */
    targetDomainId: string;
    /**
     * 
     * @type {ImportDomain}
     * @memberof ImportButtonRequest
     */
    sourceDomain?: ImportDomain;
    /**
     * 
     * @type {Array<Button>}
     * @memberof ImportButtonRequest
     */
    buttons: Array<Button>;
}

/**
 * Check if a given object implements the ImportButtonRequest interface.
 */
export function instanceOfImportButtonRequest(value: object): value is ImportButtonRequest {
    if (!('targetDomainId' in value) || value['targetDomainId'] === undefined) return false;
    if (!('buttons' in value) || value['buttons'] === undefined) return false;
    return true;
}

export function ImportButtonRequestFromJSON(json: any): ImportButtonRequest {
    return ImportButtonRequestFromJSONTyped(json, false);
}

export function ImportButtonRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportButtonRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'targetDomainId': json['targetDomainId'],
        'sourceDomain': json['sourceDomain'] == null ? undefined : ImportDomainFromJSON(json['sourceDomain']),
        'buttons': ((json['buttons'] as Array<any>).map(ButtonFromJSON)),
    };
}

export function ImportButtonRequestToJSON(json: any): ImportButtonRequest {
    return ImportButtonRequestToJSONTyped(json, false);
}

export function ImportButtonRequestToJSONTyped(value?: ImportButtonRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'targetDomainId': value['targetDomainId'],
        'sourceDomain': ImportDomainToJSON(value['sourceDomain']),
        'buttons': ((value['buttons'] as Array<any>).map(ButtonToJSON)),
    };
}

