/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Condition } from './Condition';
import {
    ConditionFromJSON,
    ConditionFromJSONTyped,
    ConditionToJSON,
    ConditionToJSONTyped,
} from './Condition';

/**
 * 
 * @export
 * @interface ConditionDeleteResponse
 */
export interface ConditionDeleteResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ConditionDeleteResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConditionDeleteResponse
     */
    id: string;
    /**
     * 
     * @type {Condition}
     * @memberof ConditionDeleteResponse
     */
    object: Condition;
}

/**
 * Check if a given object implements the ConditionDeleteResponse interface.
 */
export function instanceOfConditionDeleteResponse(value: object): value is ConditionDeleteResponse {
    if (!('success' in value) || value['success'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('object' in value) || value['object'] === undefined) return false;
    return true;
}

export function ConditionDeleteResponseFromJSON(json: any): ConditionDeleteResponse {
    return ConditionDeleteResponseFromJSONTyped(json, false);
}

export function ConditionDeleteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionDeleteResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'],
        'id': json['id'],
        'object': ConditionFromJSON(json['object']),
    };
}

export function ConditionDeleteResponseToJSON(json: any): ConditionDeleteResponse {
    return ConditionDeleteResponseToJSONTyped(json, false);
}

export function ConditionDeleteResponseToJSONTyped(value?: ConditionDeleteResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'success': value['success'],
        'id': value['id'],
        'object': ConditionToJSON(value['object']),
    };
}

