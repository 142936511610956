/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiServerExceptionResponse,
  WordPressInfo,
} from '../models/index';
import {
    ApiServerExceptionResponseFromJSON,
    ApiServerExceptionResponseToJSON,
    WordPressInfoFromJSON,
    WordPressInfoToJSON,
} from '../models/index';

export interface WordPressApiGetDomainRequest {
    domainName: string;
}

/**
 * 
 */
export class WordPressApi extends runtime.BaseAPI {

    /**
     *          Collects all relevant information needed for most WordPress pages,         specifically: the current WordPress domain, all domains, buttons, actions, conditions,         as well as the current action Stripe coupon and the relevant Stripe plans     
     * Get all WordPress specific information
     */
    async getDomainRaw(requestParameters: WordPressApiGetDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WordPressInfo>> {
        if (requestParameters['domainName'] == null) {
            throw new runtime.RequiredError(
                'domainName',
                'Required parameter "domainName" was null or undefined when calling getDomain().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/wp/all/{domainName}`.replace(`{${"domainName"}}`, encodeURIComponent(String(requestParameters['domainName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WordPressInfoFromJSON(jsonValue));
    }

    /**
     *          Collects all relevant information needed for most WordPress pages,         specifically: the current WordPress domain, all domains, buttons, actions, conditions,         as well as the current action Stripe coupon and the relevant Stripe plans     
     * Get all WordPress specific information
     */
    async getDomain(requestParameters: WordPressApiGetDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WordPressInfo> {
        const response = await this.getDomainRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
