/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Validation message, shown to the User when a validation fails or requires attention
 * @export
 * @interface Validation
 */
export interface Validation {
    /**
     * 
     * @type {string}
     * @memberof Validation
     */
    type: ValidationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Validation
     */
    message: string;
}


/**
 * @export
 */
export const ValidationTypeEnum = {
    Info: 'INFO',
    Warning: 'WARNING',
    Error: 'ERROR'
} as const;
export type ValidationTypeEnum = typeof ValidationTypeEnum[keyof typeof ValidationTypeEnum];


/**
 * Check if a given object implements the Validation interface.
 */
export function instanceOfValidation(value: object): value is Validation {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function ValidationFromJSON(json: any): Validation {
    return ValidationFromJSONTyped(json, false);
}

export function ValidationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Validation {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'message': json['message'],
    };
}

export function ValidationToJSON(json: any): Validation {
    return ValidationToJSONTyped(json, false);
}

export function ValidationToJSONTyped(value?: Validation | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'type': value['type'],
        'message': value['message'],
    };
}

