/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    fileStorageImplementation?: string;
}

/**
 * Check if a given object implements the UserSettings interface.
 */
export function instanceOfUserSettings(value: object): value is UserSettings {
    return true;
}

export function UserSettingsFromJSON(json: any): UserSettings {
    return UserSettingsFromJSONTyped(json, false);
}

export function UserSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'fileStorageImplementation': json['fileStorageImplementation'] == null ? undefined : json['fileStorageImplementation'],
    };
}

export function UserSettingsToJSON(json: any): UserSettings {
    return UserSettingsToJSONTyped(json, false);
}

export function UserSettingsToJSONTyped(value?: UserSettings | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'fileStorageImplementation': value['fileStorageImplementation'],
    };
}

