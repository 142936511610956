import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import { useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "../../common";
import { DomainContext } from "../../domain";
import logoShort from "../../assets/nb-logo-icon.png";
import logo from "../../assets/nb-logo-full.png";
import { Box } from "@mui/material";
import OutstandingPaymentNote from "../../domain/components/OutstandingPaymentNote";
import UpgradeButton from "./UpgradeButton";
import { DomainType } from "../../domain/domainModels";
import { AuthContext } from "../../auth";
import { QuestionAnswer } from "@mui/icons-material";
import { Domain } from "../../lib/cnb/api/public";

const useStyles = makeStyles()((theme) => ({
  logo: {
    justifyContent: "center",
    alignItems: "center",
    display: "inline-flex",
    img: {
      height: 25,
    },
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  domainButton: {
    color: "inherit",
    textTransform: "initial",
    marginLeft: theme.spacing(2),
    paddingRight: 0,
    backgroundColor: alpha(theme.palette.common.white, 0.1),
    "&:hover": { backgroundColor: alpha(theme.palette.common.white, 0.2) },
  },
  domainType: {
    backgroundColor: alpha(theme.palette.primary.light, 0.15),
    color: alpha(theme.palette.primary.light, 1),
    padding: theme.spacing(0.5),
    marginLeft: "0.5em",
    borderRadius: theme.shape.borderRadius,
    lineHeight: 1,
    fontSize: "0.8em",
  },
  domainSettingsIcon: {
    marginLeft: -4,
    marginRight: 4,
  },
  addDomainIcon: {
    marginLeft: theme.spacing(-0.5),
  },
  headerButton: {
    marginLeft: theme.spacing(2),
    color: alpha(theme.palette.primary.contrastText, 0.8),
    "&:hover": { color: theme.palette.primary.contrastText },
    borderColor: "currentColor",
  },
  headerButtonBox: {
    marginLeft: theme.spacing(2),
  },
  headerIconButton: {
    padding: theme.spacing(0.5),
  },
  headerMenu: {
    marginTop: theme.spacing(6),
  },
  desktopOnly: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileOnly: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const Header = () => {
  const { currentDomain } = useContext(DomainContext);
  const { classes, cx } = useStyles();

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#111827" }}>
      <Toolbar className={classes.toolbar}>
        <Box className={cx(classes.logo, classes.desktopOnly)}>
          <img src={logo} alt="logo" />
        </Box>
        <Box className={cx(classes.logo, classes.mobileOnly)}>
          <img src={logoShort} alt="logo" />
        </Box>
        <OutstandingPaymentNote />
        {currentDomain && (
          <>
            <DomainMenu currentDomain={currentDomain} />
            <DomainSettingsIcon />
            <DomainUpgradeButton />
          </>
        )}
        <div className={classes.grow} />
        <ChatLink />
        <AccountLink />
      </Toolbar>
    </AppBar>
  );
};

const DomainUpgradeButton = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.headerButtonBox}>
      <UpgradeButton />
    </Box>
  );
};

const DomainSettingsIcon = () => {
  const { classes, cx } = useStyles();
  return (
    <IconButton
      component={RouterLink}
      to={routes.DOMAIN_EDIT.path}
      color="inherit"
      className={cx(
        classes.headerButton,
        classes.headerIconButton,
        classes.desktopOnly,
      )}
      size="large"
    >
      <SettingsIcon />
    </IconButton>
  );
};

const DomainMenu = ({ currentDomain }: { currentDomain: Domain }) => {
  const [domainMenu, setDomainMenu] = useState<null | HTMLElement>(null);
  const { domains, setCurrentDomain } = useContext(DomainContext);
  const { classes } = useStyles();

  return (
    <>
      <Button
        variant="contained"
        className={classes.domainButton}
        onClick={(event) => setDomainMenu(event.currentTarget)}
      >
        {currentDomain.name}
        <span className={classes.domainType}>
          {DomainType.TYPE_NAME[currentDomain.type]}
        </span>
        <ArrowDropDownIcon />
      </Button>
      <Menu
        open={Boolean(domainMenu)}
        anchorEl={domainMenu}
        onClose={() => setDomainMenu(null)}
        className={classes.headerMenu}
        disableAutoFocusItem
      >
        <MenuItem
          component={RouterLink}
          to={routes.DOMAIN_EDIT.path}
          className={classes.mobileOnly}
          onClick={() => setDomainMenu(null)}
        >
          <SettingsIcon className={classes.domainSettingsIcon} />
          Manage
        </MenuItem>
        <Divider className={classes.mobileOnly} />
        {Object.values(domains || {}).map((domain) => (
          <MenuItem
            key={domain.id}
            onClick={() => {
              setCurrentDomain(domain.id);
              setDomainMenu(null);
            }}
          >
            {domain.name}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          component={RouterLink}
          to={routes.DOMAIN_NEW.path}
          onClick={() => setDomainMenu(null)}
        >
          <AddIcon className={classes.addDomainIcon} />
          Add domain
        </MenuItem>
      </Menu>
    </>
  );
};

const AccountLink = () => {
  const { classes, cx } = useStyles();
  return (
    <IconButton
      component={RouterLink}
      to={routes.PROFILE.path}
      color="inherit"
      className={cx(classes.headerButton, classes.headerIconButton)}
      size="large"
    >
      <AccountIcon />
    </IconButton>
  );
};

const ChatLink = () => {
  const { currentUser } = useContext(AuthContext);
  const { classes, cx } = useStyles();

  const isChatUser = currentUser?.roles?.some(
    (role) => role === "ROLE_CHAT_USER",
  );

  if (!process.env.REACT_APP_CHAT_URL) return <></>;
  if (!isChatUser) return null;
  return (
    <Box>
      <IconButton
        component="a"
        href={process.env.REACT_APP_CHAT_URL}
        target="_blank"
        rel="noopener noreferrer"
        color="inherit"
        className={cx(classes.headerButton, classes.headerIconButton)}
        size="large"
      >
        <QuestionAnswer />
      </IconButton>
    </Box>
  );
};

export default Header;
