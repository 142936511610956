/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiServerExceptionResponse,
  Domain,
  DomainCreateRequest,
  DomainDeleteResponse,
  DomainUpdateRequest,
} from '../models/index';
import {
    ApiServerExceptionResponseFromJSON,
    ApiServerExceptionResponseToJSON,
    DomainFromJSON,
    DomainToJSON,
    DomainCreateRequestFromJSON,
    DomainCreateRequestToJSON,
    DomainDeleteResponseFromJSON,
    DomainDeleteResponseToJSON,
    DomainUpdateRequestFromJSON,
    DomainUpdateRequestToJSON,
} from '../models/index';

export interface DomainApiCreateRequest {
    domainCreateRequest: DomainCreateRequest;
}

export interface DomainApiDeleteRequest {
    domainId: string;
}

export interface DomainApiGetRequest {
    domainId: string;
}

export interface DomainApiGetByNameRequest {
    name: string;
}

export interface DomainApiUpdateRequest {
    domainId: string;
    domainUpdateRequest: DomainUpdateRequest;
}

export interface DomainApiUpdateTypeRequest {
    domainId: string;
    type: UpdateTypeTypeEnum;
}

/**
 * 
 */
export class DomainApi extends runtime.BaseAPI {

    /**
     * Create a new `Domain`.
     */
    async createRaw(requestParameters: DomainApiCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Domain>> {
        if (requestParameters['domainCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'domainCreateRequest',
                'Required parameter "domainCreateRequest" was null or undefined when calling create().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/domain`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DomainCreateRequestToJSON(requestParameters['domainCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainFromJSON(jsonValue));
    }

    /**
     * Create a new `Domain`.
     */
    async create(requestParameters: DomainApiCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Domain> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a `Domain`.
     */
    async deleteRaw(requestParameters: DomainApiDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DomainDeleteResponse>> {
        if (requestParameters['domainId'] == null) {
            throw new runtime.RequiredError(
                'domainId',
                'Required parameter "domainId" was null or undefined when calling delete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/domain/{domainId}`.replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters['domainId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainDeleteResponseFromJSON(jsonValue));
    }

    /**
     * Delete a `Domain`.
     */
    async delete(requestParameters: DomainApiDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DomainDeleteResponse> {
        const response = await this.deleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a `Domain` by its ID
     */
    async getRaw(requestParameters: DomainApiGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Domain>> {
        if (requestParameters['domainId'] == null) {
            throw new runtime.RequiredError(
                'domainId',
                'Required parameter "domainId" was null or undefined when calling get().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/domain/{domainId}`.replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters['domainId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainFromJSON(jsonValue));
    }

    /**
     * Get a `Domain` by its ID
     */
    async get(requestParameters: DomainApiGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Domain> {
        const response = await this.getRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all `Domain`s
     */
    async getAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Domain>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/domain`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DomainFromJSON));
    }

    /**
     * Get all `Domain`s
     */
    async getAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Domain>> {
        const response = await this.getAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a `Domain` by its name
     */
    async getByNameRaw(requestParameters: DomainApiGetByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Domain>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling getByName().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/domain/byName/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainFromJSON(jsonValue));
    }

    /**
     * Get a `Domain` by its name
     */
    async getByName(requestParameters: DomainApiGetByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Domain> {
        const response = await this.getByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a `Domain`.
     */
    async updateRaw(requestParameters: DomainApiUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Domain>> {
        if (requestParameters['domainId'] == null) {
            throw new runtime.RequiredError(
                'domainId',
                'Required parameter "domainId" was null or undefined when calling update().'
            );
        }

        if (requestParameters['domainUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'domainUpdateRequest',
                'Required parameter "domainUpdateRequest" was null or undefined when calling update().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/domain/{domainId}`.replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters['domainId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DomainUpdateRequestToJSON(requestParameters['domainUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainFromJSON(jsonValue));
    }

    /**
     * Update a `Domain`.
     */
    async update(requestParameters: DomainApiUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Domain> {
        const response = await this.updateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the type of a `Domain`. Only for PRO Accounts
     */
    async updateTypeRaw(requestParameters: DomainApiUpdateTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Domain>> {
        if (requestParameters['domainId'] == null) {
            throw new runtime.RequiredError(
                'domainId',
                'Required parameter "domainId" was null or undefined when calling updateType().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling updateType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/domain/{domainId}/type/{type}`.replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters['domainId']))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainFromJSON(jsonValue));
    }

    /**
     * Update the type of a `Domain`. Only for PRO Accounts
     */
    async updateType(requestParameters: DomainApiUpdateTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Domain> {
        const response = await this.updateTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const UpdateTypeTypeEnum = {
    Free: 'FREE',
    Pro: 'PRO',
    Starter: 'STARTER'
} as const;
export type UpdateTypeTypeEnum = typeof UpdateTypeTypeEnum[keyof typeof UpdateTypeTypeEnum];
