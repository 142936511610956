/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApikeyCreateRequest
 */
export interface ApikeyCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ApikeyCreateRequest
     */
    name?: string;
}

/**
 * Check if a given object implements the ApikeyCreateRequest interface.
 */
export function instanceOfApikeyCreateRequest(value: object): value is ApikeyCreateRequest {
    return true;
}

export function ApikeyCreateRequestFromJSON(json: any): ApikeyCreateRequest {
    return ApikeyCreateRequestFromJSONTyped(json, false);
}

export function ApikeyCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApikeyCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function ApikeyCreateRequestToJSON(json: any): ApikeyCreateRequest {
    return ApikeyCreateRequestToJSONTyped(json, false);
}

export function ApikeyCreateRequestToJSONTyped(value?: ApikeyCreateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
    };
}

