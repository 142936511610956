/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProAccountCheckoutSessionResponse
 */
export interface ProAccountCheckoutSessionResponse {
    /**
     * 
     * @type {string}
     * @memberof ProAccountCheckoutSessionResponse
     */
    checkoutSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof ProAccountCheckoutSessionResponse
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof ProAccountCheckoutSessionResponse
     */
    clientSecret?: string;
}

/**
 * Check if a given object implements the ProAccountCheckoutSessionResponse interface.
 */
export function instanceOfProAccountCheckoutSessionResponse(value: object): value is ProAccountCheckoutSessionResponse {
    if (!('checkoutSessionId' in value) || value['checkoutSessionId'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function ProAccountCheckoutSessionResponseFromJSON(json: any): ProAccountCheckoutSessionResponse {
    return ProAccountCheckoutSessionResponseFromJSONTyped(json, false);
}

export function ProAccountCheckoutSessionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProAccountCheckoutSessionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'checkoutSessionId': json['checkoutSessionId'],
        'url': json['url'],
        'clientSecret': json['clientSecret'] == null ? undefined : json['clientSecret'],
    };
}

export function ProAccountCheckoutSessionResponseToJSON(json: any): ProAccountCheckoutSessionResponse {
    return ProAccountCheckoutSessionResponseToJSONTyped(json, false);
}

export function ProAccountCheckoutSessionResponseToJSONTyped(value?: ProAccountCheckoutSessionResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'checkoutSessionId': value['checkoutSessionId'],
        'url': value['url'],
        'clientSecret': value['clientSecret'],
    };
}

