/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Action,
  ActionCreateRequest,
  ActionDeleteResponse,
  ActionUpdateRequest,
  ApiServerExceptionResponse,
  Button,
  ButtonMinimal,
} from '../models/index';
import {
    ActionFromJSON,
    ActionToJSON,
    ActionCreateRequestFromJSON,
    ActionCreateRequestToJSON,
    ActionDeleteResponseFromJSON,
    ActionDeleteResponseToJSON,
    ActionUpdateRequestFromJSON,
    ActionUpdateRequestToJSON,
    ApiServerExceptionResponseFromJSON,
    ApiServerExceptionResponseToJSON,
    ButtonFromJSON,
    ButtonToJSON,
    ButtonMinimalFromJSON,
    ButtonMinimalToJSON,
} from '../models/index';

export interface ActionApiCreateRequest {
    actionCreateRequest: ActionCreateRequest;
}

export interface ActionApiDeleteRequest {
    actionId: string;
}

export interface ActionApiGetRequest {
    actionId: string;
}

export interface ActionApiGetButtonsRequest {
    actionId: string;
}

export interface ActionApiGetButtonsFullRequest {
    actionId: string;
}

export interface ActionApiUpdateRequest {
    actionId: string;
    actionUpdateRequest: ActionUpdateRequest;
}

/**
 * 
 */
export class ActionApi extends runtime.BaseAPI {

    /**
     * Create an `Action`
     */
    async createRaw(requestParameters: ActionApiCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Action>> {
        if (requestParameters['actionCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'actionCreateRequest',
                'Required parameter "actionCreateRequest" was null or undefined when calling create().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/action`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActionCreateRequestToJSON(requestParameters['actionCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionFromJSON(jsonValue));
    }

    /**
     * Create an `Action`
     */
    async create(requestParameters: ActionApiCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Action> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an `Action`
     */
    async deleteRaw(requestParameters: ActionApiDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActionDeleteResponse>> {
        if (requestParameters['actionId'] == null) {
            throw new runtime.RequiredError(
                'actionId',
                'Required parameter "actionId" was null or undefined when calling delete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/action/{actionId}`.replace(`{${"actionId"}}`, encodeURIComponent(String(requestParameters['actionId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionDeleteResponseFromJSON(jsonValue));
    }

    /**
     * Delete an `Action`
     */
    async delete(requestParameters: ActionApiDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActionDeleteResponse> {
        const response = await this.deleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an `Action` by its ID
     */
    async getRaw(requestParameters: ActionApiGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Action>> {
        if (requestParameters['actionId'] == null) {
            throw new runtime.RequiredError(
                'actionId',
                'Required parameter "actionId" was null or undefined when calling get().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/action/{actionId}`.replace(`{${"actionId"}}`, encodeURIComponent(String(requestParameters['actionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionFromJSON(jsonValue));
    }

    /**
     * Get an `Action` by its ID
     */
    async get(requestParameters: ActionApiGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Action> {
        const response = await this.getRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all `Action`s for this User
     */
    async getAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/action`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
    }

    /**
     * Get all `Action`s for this User
     */
    async getAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
        const response = await this.getAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the `Button`s associated with this `Action`
     */
    async getButtonsRaw(requestParameters: ActionApiGetButtonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ButtonMinimal>>> {
        if (requestParameters['actionId'] == null) {
            throw new runtime.RequiredError(
                'actionId',
                'Required parameter "actionId" was null or undefined when calling getButtons().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/action/button/{actionId}`.replace(`{${"actionId"}}`, encodeURIComponent(String(requestParameters['actionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ButtonMinimalFromJSON));
    }

    /**
     * Get the `Button`s associated with this `Action`
     */
    async getButtons(requestParameters: ActionApiGetButtonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ButtonMinimal>> {
        const response = await this.getButtonsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the associated single Full `Button` for this `Action`
     */
    async getButtonsFullRaw(requestParameters: ActionApiGetButtonsFullRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Button>> {
        if (requestParameters['actionId'] == null) {
            throw new runtime.RequiredError(
                'actionId',
                'Required parameter "actionId" was null or undefined when calling getButtonsFull().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/action/button/{actionId}/full`.replace(`{${"actionId"}}`, encodeURIComponent(String(requestParameters['actionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ButtonFromJSON(jsonValue));
    }

    /**
     * Get the associated single Full `Button` for this `Action`
     */
    async getButtonsFull(requestParameters: ActionApiGetButtonsFullRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Button> {
        const response = await this.getButtonsFullRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an `Action`
     */
    async updateRaw(requestParameters: ActionApiUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Action>> {
        if (requestParameters['actionId'] == null) {
            throw new runtime.RequiredError(
                'actionId',
                'Required parameter "actionId" was null or undefined when calling update().'
            );
        }

        if (requestParameters['actionUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'actionUpdateRequest',
                'Required parameter "actionUpdateRequest" was null or undefined when calling update().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/action/{actionId}`.replace(`{${"actionId"}}`, encodeURIComponent(String(requestParameters['actionId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ActionUpdateRequestToJSON(requestParameters['actionUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionFromJSON(jsonValue));
    }

    /**
     * Update an `Action`
     */
    async update(requestParameters: ActionApiUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Action> {
        const response = await this.updateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
