import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import LockIcon from "@mui/icons-material/Lock";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import * as yup from "yup";
import { useExtendedForm, routes } from "../common";
import { AuthContext } from "./useAuth";
import { AuthPage } from "./components";

const useStyles = makeStyles()({
  rowLayout: {
    display: "flex",
    justifyContent: "space-between",
  },
  forgotPassword: {
    display: "inline-flex",
    alignItems: "center",
    marginBottom: 16,

    "& svg": {
      fontSize: "1em",
    },
  },
});

const initialValues = {
  email: "",
  password: "",
  remember: false,
};

const schema = yup
  .object<typeof initialValues>()
  .shape({
    email: yup.string().email().required("email is missing"),
    password: yup.string().required("password is missing"),
  })
  .defined();

const LoginPage: React.FC = () => {
  const { classes } = useStyles();
  const { login } = React.useContext(AuthContext);
  const formal = useExtendedForm({
    initialValues,
    schema,
    onSubmit: (values) => login(values.email, values.password, values.remember),
  });

  return (
    <AuthPage>
      <form {...formal.getFormProps()}>
        <FormGroup>
          <Typography variant="h5" align="center" paragraph>
            Log in to continue
          </Typography>
          <TextField
            label="Email address"
            type="email"
            {...formal.getTextFieldProps("email")}
          />
          <TextField
            label="Password"
            type="password"
            {...formal.getTextFieldProps("password")}
          />
          <div className={classes.rowLayout}>
            <FormControlLabel
              control={<Checkbox {...formal.getCheckboxProps("remember")} />}
              label="Remember me"
            />
            <Typography variant="body1" className={classes.forgotPassword}>
              <Link component={RouterLink} to={routes.FORGOT_PASSWORD.path}>
                <LockIcon /> Forgot password?
              </Link>
            </Typography>
          </div>
          <p>
            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              {...formal.getSubmitButtonProps()}
              type="submit"
            >
              Log In
            </Button>
            {formal.submitState.state === "error" && (
              <FormHelperText error>
                {formal.submitState.message ||
                  "Invalid username or password. Please try again."}
              </FormHelperText>
            )}
          </p>
          <Typography variant="body1" align="center">
            Don't have an account?{" "}
            <Link component={RouterLink} to={routes.REGISTER.path}>
              Sign Up
            </Link>
          </Typography>
        </FormGroup>
      </form>
    </AuthPage>
  );
};

export default LoginPage;
