/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ButtonOptions } from './ButtonOptions';
import {
    ButtonOptionsFromJSON,
    ButtonOptionsFromJSONTyped,
    ButtonOptionsToJSON,
    ButtonOptionsToJSONTyped,
} from './ButtonOptions';
import type { ConditionUpdateRequest } from './ConditionUpdateRequest';
import {
    ConditionUpdateRequestFromJSON,
    ConditionUpdateRequestFromJSONTyped,
    ConditionUpdateRequestToJSON,
    ConditionUpdateRequestToJSONTyped,
} from './ConditionUpdateRequest';
import type { Domain } from './Domain';
import {
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
    DomainToJSONTyped,
} from './Domain';
import type { ActionUpdateRequest } from './ActionUpdateRequest';
import {
    ActionUpdateRequestFromJSON,
    ActionUpdateRequestFromJSONTyped,
    ActionUpdateRequestToJSON,
    ActionUpdateRequestToJSONTyped,
} from './ActionUpdateRequest';
import type { MultiButtonOptions } from './MultiButtonOptions';
import {
    MultiButtonOptionsFromJSON,
    MultiButtonOptionsFromJSONTyped,
    MultiButtonOptionsToJSON,
    MultiButtonOptionsToJSONTyped,
} from './MultiButtonOptions';

/**
 * 
 * @export
 * @interface ButtonUpdateRequestV2
 */
export interface ButtonUpdateRequestV2 {
    /**
     * 
     * @type {string}
     * @memberof ButtonUpdateRequestV2
     */
    id: string;
    /**
     * 
     * @type {Domain}
     * @memberof ButtonUpdateRequestV2
     */
    domain: Domain;
    /**
     * 
     * @type {boolean}
     * @memberof ButtonUpdateRequestV2
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof ButtonUpdateRequestV2
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ButtonUpdateRequestV2
     */
    type: ButtonUpdateRequestV2TypeEnum;
    /**
     * 
     * @type {ButtonOptions}
     * @memberof ButtonUpdateRequestV2
     */
    options: ButtonOptions;
    /**
     * 
     * @type {MultiButtonOptions}
     * @memberof ButtonUpdateRequestV2
     */
    multiButtonOptions?: MultiButtonOptions;
    /**
     * 
     * @type {Array<ActionUpdateRequest>}
     * @memberof ButtonUpdateRequestV2
     */
    actions: Array<ActionUpdateRequest>;
    /**
     * 
     * @type {Array<ConditionUpdateRequest>}
     * @memberof ButtonUpdateRequestV2
     */
    conditions: Array<ConditionUpdateRequest>;
}


/**
 * @export
 */
export const ButtonUpdateRequestV2TypeEnum = {
    Single: 'SINGLE',
    Multi: 'MULTI',
    Full: 'FULL',
    Dots: 'DOTS'
} as const;
export type ButtonUpdateRequestV2TypeEnum = typeof ButtonUpdateRequestV2TypeEnum[keyof typeof ButtonUpdateRequestV2TypeEnum];


/**
 * Check if a given object implements the ButtonUpdateRequestV2 interface.
 */
export function instanceOfButtonUpdateRequestV2(value: object): value is ButtonUpdateRequestV2 {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('domain' in value) || value['domain'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    if (!('actions' in value) || value['actions'] === undefined) return false;
    if (!('conditions' in value) || value['conditions'] === undefined) return false;
    return true;
}

export function ButtonUpdateRequestV2FromJSON(json: any): ButtonUpdateRequestV2 {
    return ButtonUpdateRequestV2FromJSONTyped(json, false);
}

export function ButtonUpdateRequestV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): ButtonUpdateRequestV2 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'domain': DomainFromJSON(json['domain']),
        'active': json['active'],
        'name': json['name'],
        'type': json['type'],
        'options': ButtonOptionsFromJSON(json['options']),
        'multiButtonOptions': json['multiButtonOptions'] == null ? undefined : MultiButtonOptionsFromJSON(json['multiButtonOptions']),
        'actions': ((json['actions'] as Array<any>).map(ActionUpdateRequestFromJSON)),
        'conditions': ((json['conditions'] as Array<any>).map(ConditionUpdateRequestFromJSON)),
    };
}

export function ButtonUpdateRequestV2ToJSON(json: any): ButtonUpdateRequestV2 {
    return ButtonUpdateRequestV2ToJSONTyped(json, false);
}

export function ButtonUpdateRequestV2ToJSONTyped(value?: ButtonUpdateRequestV2 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'domain': DomainToJSON(value['domain']),
        'active': value['active'],
        'name': value['name'],
        'type': value['type'],
        'options': ButtonOptionsToJSON(value['options']),
        'multiButtonOptions': MultiButtonOptionsToJSON(value['multiButtonOptions']),
        'actions': ((value['actions'] as Array<any>).map(ActionUpdateRequestToJSON)),
        'conditions': ((value['conditions'] as Array<any>).map(ConditionUpdateRequestToJSON)),
    };
}

