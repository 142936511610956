/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ButtonOptions } from './ButtonOptions';
import {
    ButtonOptionsFromJSON,
    ButtonOptionsFromJSONTyped,
    ButtonOptionsToJSON,
    ButtonOptionsToJSONTyped,
} from './ButtonOptions';
import type { MultiButtonOptions } from './MultiButtonOptions';
import {
    MultiButtonOptionsFromJSON,
    MultiButtonOptionsFromJSONTyped,
    MultiButtonOptionsToJSON,
    MultiButtonOptionsToJSONTyped,
} from './MultiButtonOptions';

/**
 * 
 * @export
 * @interface SettingsButton
 */
export interface SettingsButton {
    /**
     * 
     * @type {string}
     * @memberof SettingsButton
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsButton
     */
    domainId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsButton
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SettingsButton
     */
    type?: SettingsButtonTypeEnum;
    /**
     * 
     * @type {ButtonOptions}
     * @memberof SettingsButton
     */
    options?: ButtonOptions;
    /**
     * 
     * @type {MultiButtonOptions}
     * @memberof SettingsButton
     */
    multiButtonOptions?: MultiButtonOptions;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettingsButton
     */
    actions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettingsButton
     */
    conditions?: Array<string>;
}


/**
 * @export
 */
export const SettingsButtonTypeEnum = {
    Single: 'SINGLE',
    Multi: 'MULTI',
    Full: 'FULL',
    Dots: 'DOTS'
} as const;
export type SettingsButtonTypeEnum = typeof SettingsButtonTypeEnum[keyof typeof SettingsButtonTypeEnum];


/**
 * Check if a given object implements the SettingsButton interface.
 */
export function instanceOfSettingsButton(value: object): value is SettingsButton {
    return true;
}

export function SettingsButtonFromJSON(json: any): SettingsButton {
    return SettingsButtonFromJSONTyped(json, false);
}

export function SettingsButtonFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsButton {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'domainId': json['domainId'] == null ? undefined : json['domainId'],
        'active': json['active'] == null ? undefined : json['active'],
        'type': json['type'] == null ? undefined : json['type'],
        'options': json['options'] == null ? undefined : ButtonOptionsFromJSON(json['options']),
        'multiButtonOptions': json['multiButtonOptions'] == null ? undefined : MultiButtonOptionsFromJSON(json['multiButtonOptions']),
        'actions': json['actions'] == null ? undefined : json['actions'],
        'conditions': json['conditions'] == null ? undefined : json['conditions'],
    };
}

export function SettingsButtonToJSON(json: any): SettingsButton {
    return SettingsButtonToJSONTyped(json, false);
}

export function SettingsButtonToJSONTyped(value?: SettingsButton | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'domainId': value['domainId'],
        'active': value['active'],
        'type': value['type'],
        'options': ButtonOptionsToJSON(value['options']),
        'multiButtonOptions': MultiButtonOptionsToJSON(value['multiButtonOptions']),
        'actions': value['actions'],
        'conditions': value['conditions'],
    };
}

