/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Domain Properties to apply (optional)
 * @export
 * @interface ImportDomain
 */
export interface ImportDomain {
    /**
     * 
     * @type {string}
     * @memberof ImportDomain
     */
    timezone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ImportDomain
     */
    trackGA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImportDomain
     */
    trackConversion?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ImportDomain
     */
    properties?: { [key: string]: string; };
}

/**
 * Check if a given object implements the ImportDomain interface.
 */
export function instanceOfImportDomain(value: object): value is ImportDomain {
    return true;
}

export function ImportDomainFromJSON(json: any): ImportDomain {
    return ImportDomainFromJSONTyped(json, false);
}

export function ImportDomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportDomain {
    if (json == null) {
        return json;
    }
    return {
        
        'timezone': json['timezone'] == null ? undefined : json['timezone'],
        'trackGA': json['trackGA'] == null ? undefined : json['trackGA'],
        'trackConversion': json['trackConversion'] == null ? undefined : json['trackConversion'],
        'properties': json['properties'] == null ? undefined : json['properties'],
    };
}

export function ImportDomainToJSON(json: any): ImportDomain {
    return ImportDomainToJSONTyped(json, false);
}

export function ImportDomainToJSONTyped(value?: ImportDomain | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'timezone': value['timezone'],
        'trackGA': value['trackGA'],
        'trackConversion': value['trackConversion'],
        'properties': value['properties'],
    };
}

