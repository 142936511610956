/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActionSchedule
 */
export interface ActionSchedule {
    /**
     * 
     * @type {boolean}
     * @memberof ActionSchedule
     */
    showAlways?: boolean;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof ActionSchedule
     */
    daysOfWeek?: Array<boolean>;
    /**
     * 
     * @type {string}
     * @memberof ActionSchedule
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionSchedule
     */
    stop?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionSchedule
     */
    timezone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionSchedule
     */
    outsideHours?: boolean;
}

/**
 * Check if a given object implements the ActionSchedule interface.
 */
export function instanceOfActionSchedule(value: object): value is ActionSchedule {
    return true;
}

export function ActionScheduleFromJSON(json: any): ActionSchedule {
    return ActionScheduleFromJSONTyped(json, false);
}

export function ActionScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionSchedule {
    if (json == null) {
        return json;
    }
    return {
        
        'showAlways': json['showAlways'] == null ? undefined : json['showAlways'],
        'daysOfWeek': json['daysOfWeek'] == null ? undefined : json['daysOfWeek'],
        'start': json['start'] == null ? undefined : json['start'],
        'stop': json['stop'] == null ? undefined : json['stop'],
        'timezone': json['timezone'] == null ? undefined : json['timezone'],
        'outsideHours': json['outsideHours'] == null ? undefined : json['outsideHours'],
    };
}

export function ActionScheduleToJSON(json: any): ActionSchedule {
    return ActionScheduleToJSONTyped(json, false);
}

export function ActionScheduleToJSONTyped(value?: ActionSchedule | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'showAlways': value['showAlways'],
        'daysOfWeek': value['daysOfWeek'],
        'start': value['start'],
        'stop': value['stop'],
        'timezone': value['timezone'],
        'outsideHours': value['outsideHours'],
    };
}

