import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import React from "react";
import logo from "../../assets/nb-logo-stacked.png";
import { Page } from "../../common";

const WEBSITE_URL = "https://nowbuttons.com/";

export const useStyles = makeStyles()((theme) => ({
  root: {
    "& form": {
      maxWidth: 320,
      margin: theme.spacing(2.5, "auto"),
    },
  },
  logo: {
    height: 90,
    display: "block",
    margin: theme.spacing(2.5, "auto"),
  },
  footer: {
    textAlign: "center",
    color: theme.palette.grey[500],
    margin: theme.spacing("auto", 0, -2),
    paddingTop: theme.spacing(3),
  },
}));

const AuthPage: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { classes } = useStyles();
  return (
    <Page anchor="right" className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      {children}
      <Typography
        variant="caption"
        component="footer"
        className={classes.footer}
      >
        © {new Date().getFullYear()} <Link href={WEBSITE_URL}>NowButtons</Link>{" "}
        by StudioStacks B.V.
      </Typography>
    </Page>
  );
};

export default AuthPage;
