/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StripeAgencyPlanPrice } from './StripeAgencyPlanPrice';
import {
    StripeAgencyPlanPriceFromJSON,
    StripeAgencyPlanPriceFromJSONTyped,
    StripeAgencyPlanPriceToJSON,
    StripeAgencyPlanPriceToJSONTyped,
} from './StripeAgencyPlanPrice';

/**
 * A Stripe plan, which is a subscription plan that can be used to create a subscription.
 * @export
 * @interface StripeAgencyPlan
 */
export interface StripeAgencyPlan {
    /**
     * 
     * @type {string}
     * @memberof StripeAgencyPlan
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StripeAgencyPlan
     */
    nickname: string;
    /**
     * 
     * @type {Array<StripeAgencyPlanPrice>}
     * @memberof StripeAgencyPlan
     */
    prices: Array<StripeAgencyPlanPrice>;
    /**
     * As part of a Stripe Subscription (or checkout session), to indicate the payment interval.
     * @type {string}
     * @memberof StripeAgencyPlan
     */
    interval: StripeAgencyPlanIntervalEnum;
    /**
     * 
     * @type {number}
     * @memberof StripeAgencyPlan
     */
    seats: number;
}


/**
 * @export
 */
export const StripeAgencyPlanIntervalEnum = {
    Monthly: 'monthly',
    Yearly: 'yearly'
} as const;
export type StripeAgencyPlanIntervalEnum = typeof StripeAgencyPlanIntervalEnum[keyof typeof StripeAgencyPlanIntervalEnum];


/**
 * Check if a given object implements the StripeAgencyPlan interface.
 */
export function instanceOfStripeAgencyPlan(value: object): value is StripeAgencyPlan {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('nickname' in value) || value['nickname'] === undefined) return false;
    if (!('prices' in value) || value['prices'] === undefined) return false;
    if (!('interval' in value) || value['interval'] === undefined) return false;
    if (!('seats' in value) || value['seats'] === undefined) return false;
    return true;
}

export function StripeAgencyPlanFromJSON(json: any): StripeAgencyPlan {
    return StripeAgencyPlanFromJSONTyped(json, false);
}

export function StripeAgencyPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeAgencyPlan {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'nickname': json['nickname'],
        'prices': ((json['prices'] as Array<any>).map(StripeAgencyPlanPriceFromJSON)),
        'interval': json['interval'],
        'seats': json['seats'],
    };
}

export function StripeAgencyPlanToJSON(json: any): StripeAgencyPlan {
    return StripeAgencyPlanToJSONTyped(json, false);
}

export function StripeAgencyPlanToJSONTyped(value?: StripeAgencyPlan | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'nickname': value['nickname'],
        'prices': ((value['prices'] as Array<any>).map(StripeAgencyPlanPriceToJSON)),
        'interval': value['interval'],
        'seats': value['seats'],
    };
}

