/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ButtonMinimal } from './ButtonMinimal';
import {
    ButtonMinimalFromJSON,
    ButtonMinimalFromJSONTyped,
    ButtonMinimalToJSON,
    ButtonMinimalToJSONTyped,
} from './ButtonMinimal';

/**
 * 
 * @export
 * @interface DeleteButtonResponse
 */
export interface DeleteButtonResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteButtonResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeleteButtonResponse
     */
    id: string;
    /**
     * 
     * @type {ButtonMinimal}
     * @memberof DeleteButtonResponse
     */
    object: ButtonMinimal;
}

/**
 * Check if a given object implements the DeleteButtonResponse interface.
 */
export function instanceOfDeleteButtonResponse(value: object): value is DeleteButtonResponse {
    if (!('success' in value) || value['success'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('object' in value) || value['object'] === undefined) return false;
    return true;
}

export function DeleteButtonResponseFromJSON(json: any): DeleteButtonResponse {
    return DeleteButtonResponseFromJSONTyped(json, false);
}

export function DeleteButtonResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteButtonResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'],
        'id': json['id'],
        'object': ButtonMinimalFromJSON(json['object']),
    };
}

export function DeleteButtonResponseToJSON(json: any): DeleteButtonResponse {
    return DeleteButtonResponseToJSONTyped(json, false);
}

export function DeleteButtonResponseToJSONTyped(value?: DeleteButtonResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'success': value['success'],
        'id': value['id'],
        'object': ButtonMinimalToJSON(value['object']),
    };
}

