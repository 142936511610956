/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.203-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This is the same object as Apikey, but with the API key embedded as the key property.
 * @export
 * @interface ApikeyWithKey
 */
export interface ApikeyWithKey {
    /**
     * 
     * @type {string}
     * @memberof ApikeyWithKey
     */
    id: string;
    /**
     * The API key that can be used to query the NowButtons API.
     * @type {string}
     * @memberof ApikeyWithKey
     */
    key: string;
    /**
     * The user ID of the user who created this API.
     * @type {string}
     * @memberof ApikeyWithKey
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof ApikeyWithKey
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof ApikeyWithKey
     */
    lastUsed?: Date;
}

/**
 * Check if a given object implements the ApikeyWithKey interface.
 */
export function instanceOfApikeyWithKey(value: object): value is ApikeyWithKey {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function ApikeyWithKeyFromJSON(json: any): ApikeyWithKey {
    return ApikeyWithKeyFromJSONTyped(json, false);
}

export function ApikeyWithKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApikeyWithKey {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'key': json['key'],
        'user': json['user'],
        'name': json['name'],
        'lastUsed': json['lastUsed'] == null ? undefined : (new Date(json['lastUsed'])),
    };
}

export function ApikeyWithKeyToJSON(json: any): ApikeyWithKey {
    return ApikeyWithKeyToJSONTyped(json, false);
}

export function ApikeyWithKeyToJSONTyped(value?: ApikeyWithKey | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'key': value['key'],
        'user': value['user'],
        'name': value['name'],
        'lastUsed': value['lastUsed'] == null ? undefined : ((value['lastUsed']).toISOString()),
    };
}

